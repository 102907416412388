import React from 'react';
import img6 from './../../Assets/Img/banner/6.CleaningServiceWoodlandHillsHousekeeperServiceWoodlandHills.jpg'

const Service = () => {
    return (
        <>
            <section id="trash_heading section-gap">
                <div className="container section-gap">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="custom-service bg-color p-2">
                                <h4 className="mt-2 trash-h4">welcome to Hope Home Cleaning Service </h4>
                                <p className="mb-0">about Hope Home Cleaning Service </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="trash_area">
                <div className="container gap-1">
                    <div className="row">
                        <div className="col-md-6 p-2">
                            <div className="trash-img">
                                <img src={img6}
                                     className="img-thumbnail" alt="image"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trash-content text-center py-2">
                                <div className="trash-heading mt-2">
                                    <h4>welcome to Hope Home Cleaning Service </h4>
                                    <p>about Hope Home Cleaning Service </p>
                                </div>
                                <p className="py-4">Awesome! the best company that lets you clean  and rubbish
                                    anywhere LA county you want.</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="number p-0">
                                            <p> 13</p>
                                        </div>
                                        <p className="num-content">Years Of Experienced</p>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="number">
                                            <p> 2,342</p>
                                        </div>
                                        <p className="num-content">Happy Customers</p>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="number">
                                            <p>30</p>
                                        </div>
                                        <p className="num-content">Award Winning</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Service;

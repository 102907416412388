import React from 'react';

const TopBar = () => {
    return (

        <section id="top_bar">
            <div className="container-fluid top-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-md-start text-center">
                            <div className="top-bar-link">
                                <a className={'d-block d-md-inline'} href="mailto:hopehomecleaningservices@gmail.com">hopehomecleaningservices@gmail.com
                                </a>
                                <a className={'d-block d-md-inline'} href="tel:18188930246">1 (818) 893-0246
                                </a>
                            </div>

                        </div>
                        <div className="col-md-6 text-md-end text-center">
                            <div className="top-bar-social">
                                <a href="https://www.facebook.com/MegaBusinessUSAcom-294802020626441"
                                   target="_blank">
                                    <i className="fa-brands fa-facebook-f"></i>
                                </a>
                                <a href="https://twitter.com/MegaBusinessUSA" target="_blank">
                                    <i className="fa-brands fa-twitter"></i>
                                </a>
                                <a href="https://www.instagram.com/megabusinessusa" target="_blank">
                                    <i className="fa-brands fa-square-instagram"></i>
                                </a>
                                <a href="#"><span> <i className="fas fa-user"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopBar;

import React from 'react';
import img_1 from './../../Assets/Img/service/CleaningServiceWoodlandHillsHousekeeperServiceWoodlandHills.jpg'
import img_2 from './../../Assets/Img/service/CleaningServiceNorthridgeHousekeeperServiceNorthridge.jpg'
import img_3 from './../../Assets/Img/service/CleaningServicesBelAirHousekeeperBelAir.jpg'
import img_4 from './../../Assets/Img/service/CleaningServicesShermanOaksHousekeeperShermanOaks.jpg'
import img_5 from './../../Assets/Img/service/CleaningServicesTarzanaHousekeeperTarzana.jpg'
import img_6 from './../../Assets/Img/service/CleaningServicesWestHollywoodHousekeeperWestHollywood.jpg'

const About = () => {
    return (
        <>
            <div className={'container-fluid p-0'}>
                <div className="common-banner">
                    <img src={img_1} alt={'services'} className={'img-fluid'}/>
                    <div className="content">
                        <h1 className={'display-1'}>ABOUT US</h1>
                    </div>
                </div>
            </div>
            <div className="container section-gap image-containers">
                <div className="row">
                    <div className="col-md-4">
                        <img src={img_4} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_5} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_6} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                </div>
            </div>
            <div className="container about-us-section section-gap">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className={'text-uppercase text-center display-1'}>About Us</h1>
                        <p className={'text-lead my-5'}>HOPE Home Cleaning Services (818) 773-7599 Residential, Commercial and Offices cleaning services proudly offer the highest quality and most
                            affordable prices for professional house cleaning and maid services in the Los Angeles and San Fernando Valley Areas.</p>
                        <ul>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Los Angeles County</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Beverly Hills</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Calabasas,</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Canyon County</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Century City</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Chatsworth</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Encino</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Granada Hills</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Newhall</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, North Hollywood</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Northridge</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Porter Ranch</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Reseda</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Santa Clarita</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Santa Monica</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Sherman Oaks</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Simi Valley</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Stevenson Ranch</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Tarzana</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Universal City</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Valencia</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, West Hills</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, West Hollywood</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Westwood</li>
                            <li>Home Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Woodland Hills</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container section-gap image-containers">
                <div className="row">
                    <div className="col-md-4">
                        <img src={img_1} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_2} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_3} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                </div>
            </div>
        </>


    );
};

export default About;

import React from 'react';
import logo from './../../Assets/Img/logo/logo.png'
import play from './../../Assets/Img/logo/paly.png'
import ios from './../../Assets/Img/logo/app_ios.png'

const Footer = () => {
    return (
        <>
            <section id="footer">
                <div className="container-fluid bg-footer">
                    <div className="container">
                        <div className="footer">
                            <div className="row pt-5">
                                <div className="col-md-3 text-center">
                                    <a href="#"><img src={logo} alt="image"/></a>
                                    <p className="mt-3 p-2 text-justify">In fact, our employees live in the neighborhoods where we provide
                                        service, Call us at  1 (818) 893-0246, so
                                        you can count on us to take every opportunity to keep our communities clean and
                                        safe for all of our families and
                                        businesses.</p>
                                    <div className="top-bar-social my-3">
                                        <a href="https://www.facebook.com/MegaBusinessUSAcom-294802020626441"
                                           target="_blank">
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </a>
                                        <a href="https://twitter.com/MegaBusinessUSA" target="_blank">
                                            <i className="fa-brands fa-twitter"></i>
                                        </a>
                                        <a href="https://www.instagram.com/megabusinessusa" target="_blank">
                                            <i className="fa-brands fa-square-instagram"></i>
                                        </a>
                                        <a href="#"><span> <i className="fas fa-user"></i></span></a>
                                    </div>
                                </div>
                                <div className="col-md-3 text-center">
                                    <h2 className="footer-h2">Services</h2>
                                    <div className="service-footer-text mt-3 p-2">
                                        <a href=""><i className="fa fa-check pe-2"></i>Residential</a>
                                        <a href=""><i className="fa fa-check pe-2"></i>Commercial</a>
                                        <a href=""><i className="fa fa-check pe-2"></i>Carpet Cleaning</a>
                                        <a href=""><i className="fa fa-check pe-2"></i>Floor Cleaning</a>
                                        <a href=""><i className="fa fa-check pe-2"></i>Privacy Policy</a>
                                    </div>
                                </div>
                                <div className="col-md-3  text-center">
                                    <h2 className="footer-h2">Contact Us</h2>
                                    <div className="contact-footer-text mt-3">
                                        <a href="#">
                                            <i className="fa fa-home"></i> 8444 Reseda Blvd, Northridge, CA
                                        </a>
                                        <a href="tel:18188930246">
                                            <i className="fa fa-phone"></i>
                                            1 (818) 893-0246
                                        </a>
                                        <a href="mailto:hopehomecleaningservices@gmail.com">
                                            <i className="fa fa-envelope"></i> hopehomecleaningservices@gmail.com
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3 text-center">
                                    <h3 className="footer-h2">Download APPS</h3>
                                    <div className="apps-link mt-3 ps-3">
                                        <div className="app-img">
                                            <div className="row">
                                                <div className="col-6">
                                                    <img src={ios} className="img-fluid" alt="image"/>
                                                </div>
                                                <div className="col-6"><img src={play} className="img-fluid" alt="image"/></div>
                                            </div>
                                        </div>
                                        <div className="input-group my-4">
                                            <input type="email" className="form-control " placeholder="Subscribe"/>
                                            <div className="input-group-text bg-theme"><i className="fas fa-paper-plane"></i>
                                            </div>
                                        </div>

                                        <div className="privacy-link text-start">
                                            <a href="/privacy-policy">Privacy Policy</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="footer-bottom">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 footer-bar">
                            <h6 className="custom-color">
                                Copyright©Sublime Productions Inc, Mega Hot Mop,Mega Hot Mop Inc.
                                ,MegabusinessUSA.com, Sublimelist.com, Power Plumbing Drain Cleaning, Hope Home Cleaning Service  2017-2022. All rights reserved. Developed by: Sublime Productions Inc
                            </h6>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer;

import React, {useState} from 'react';

import img1 from './../../Assets/Img/banner/1.CleaningServiceWestHollywoodHousekeeperServiceWestHills.jpg'
import {Link} from "react-router-dom";

const OfferServices = () => {

    const [services] = useState([
        {'link' : '/residential', 'title': 'Residential', 'description': ' HOPE Home Cleaning Services Residential and Offices cleaning services proudly offer the highest quality and most affordable prices for professional house cleaning and maid services in USA.'},
        {'link' : '/commercial', 'title': 'Commercial', 'description': ' HOPE Home Cleaning Services Commercial and Offices cleaning services proudly offer the highest quality and most affordable prices for professional house cleaning and maid services.'},
        {'link' : '/carpet-cleaning', 'title': 'Carpet Cleaning', 'description': 'When making an effort to clean your living space, usually many items need your attention. Things like washing dishes, folding clothes or putting out the trash are relatively quick fixes.'},
        {'link' : '/floor-cleaning', 'title': 'Floor Cleaning', 'description': 'The professional floor cleaner offers you options for deep cleaning your home whether you are moving in, moving out, flood cleaning or seeking to just give it that deep, down clean feeling.'},
    ])

    return (<section id="offer_service">
        <div className="container">
            <div className="row">
                <div className="col-md-12 mt-2">
                    <div>
                        <h2 className="custom-div bg-color p-4">We offer Services</h2>
                    </div>
                </div>
                {services.map((service, index) => (
                    <div key={index} className="col-md-3  mb-15 mt-4 ">
                        <div className="card no-card-border">
                            <div className="card-body">
                                <div className="service-image text-center">
                                    <img src={img1} alt="image"/>
                                </div>
                                <div className="content">
                                    <h3 className="custom-font-h text-center mt-4">{service.title}</h3>
                                    <p className="p-1 text-14">
                                        <p className={'text-justify'}>{service.description}</p>
                                    </p>
                                </div>
                            </div>
                            <div className="card-footer text-center">
                                <button
                                    className="btn btn-md btn-default custom-btn bg-color">
                                    <Link to={service.link} className="white-anchore">Read More</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>);
};

export default OfferServices;

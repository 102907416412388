import React from 'react';
import Logo from './../../Assets/Img/logo/logo.png'
import {Link} from "react-router-dom";

const NavBar = () => {
    return (
        <section id="navbar">
            <nav className="navbar ">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <Link to="/">
                                    <img className="logo-image" src={Logo} alt="logo-image"/>
                                </Link>
                            </div>
                            <div className="col-md-9">
                                <nav>
                                    <ul className="nav-item d-flex justify-content-end mt-2 " id="nav-menu">
                                        <li><Link to="/"><i className="fa-solid fa-house"></i>Home</Link></li>
                                        <li><Link to="/about"><i className="fa-solid fa-circle-info"></i>About</Link>
                                        </li>
                                        <li>
                                            <Link to="/services"><i className="fa-solid fa-wrench"></i>Services</Link>
                                            <ul className="sub-nav-parent shadow">
                                                <li><Link to="/residential">Residential</Link></li>
                                                <li><Link to="/commercial">Commercial</Link></li>
                                                <li><Link to="/carpet-cleaning">Carpet Cleaning</Link></li>
                                                <li><Link to="/floor-cleaning">Floor Cleaning</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/blog"><i className="fa-solid fa-blog"></i>Blog</Link></li>
                                        <li><Link to="/contact"><i className="fa-solid fa-phone-volume"></i>Contact</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>


                </div>
            </nav>
        </section>
    );
};

export default NavBar;

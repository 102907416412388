import React from 'react';
import img1 from './../../Assets/Img/banner/5.CleaningServiceValenciaHousekeeperServiceValencia.jpg'
import img2 from './../../Assets/Img/banner/3.CleaningServiceEncinoHousekeeperEncino.jpg'
import img3 from './../../Assets/Img/banner/4.CleaningServiceNorthridgeHousekeeperServiceNorthridge.jpg'

const Blog = () => {
    return (
        <>
            <section id=" blog-services">
                <div className="container gap-1">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="custom-service bg-color p-2">
                                <h2 className="mt-2  -h4">News Blog</h2>
                                <h5 className="mb-0">Latest News From Our Blog</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="blog p-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 p-0 ">
                            <div>
                                <div className="img-slider">
                                    <div className="card me-2 shadow p-2 expertice">
                                        <img src={img1}
                                             alt="image"/>
                                    </div>
                                    <div className="blog-footer px-2">
                                        <p className="blog-date">25 December 2021</p>
                                        <a href="#">
                                            <h4 className="text-start blog-text">Hope Home Commercial Cleaning
                                                Service</h4>
                                        </a>
                                        <p>HOPE Home Cleaning Services (818) 773-7599 Residential, Commercial and
                                            Offices cleaning services proudly offer the highest quality and most
                                            affordable prices for professional house cleaning and maid services.
                                            <button>Read More</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-0 ">
                            <div>
                                <div className="img-slider">
                                    <div className="card me-2 shadow p-2 expertice">
                                        <img src={img2}
                                             alt="image"/>
                                    </div>
                                    <div className="blog-footer px-2">
                                        <p className="blog-date">24 February 2020</p>
                                        <a href="#">
                                            <h4 className="text-start blog-text">Hope Home Carpet Cleaning Service</h4>
                                        </a>
                                        <p>When making an effort to clean your living space, usually many items need
                                            your attention. Things like washing dishes, folding clothes or putting out
                                            the trash are relatively quick fixes.
                                            <button>Read More</button>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-0 ">
                            <div>
                                <div className="img-slider">
                                    <div className="card me-2 shadow p-2 expertice">
                                        <img src={img3}
                                             alt="image"/>
                                    </div>
                                    <div className="blog-footer px-2">
                                        <p className="blog-date">24 February 2020</p>
                                        <a href="#">
                                            <h4 className="text-start blog-text">Hope Home Floor Cleaning Service</h4>
                                        </a>
                                        <p>The professional floor cleaner offers you options for deep cleaning your home
                                            whether you are moving in, moving out, flood cleaning or seeking to just
                                            give it that deep, down clean feeling.
                                            <button>Read More</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Blog;
